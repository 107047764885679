import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "50",
  height: "50",
  viewBox: "0 0 50 50",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#212529",
      d: "M0.508301 5.52136L25 0L49.4951 5.52136C49.4951 5.52136 49.7787 16.6656 46.0445 30.4595C42.3103 44.2533 25 50 25 50C25 50 7.6897 44.2533 3.95551 30.4595C0.221317 16.6656 0.504929 5.52136 0.504929 5.52136H0.508301Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#F06F02",
      d: "M6.7181 9.30777L25.2144 5L43.7106 9.30777C43.7106 9.30777 43.9237 17.999 41.106 28.7582C38.2854 39.5175 25.2144 44 25.2144 44C25.2144 44 12.1403 39.5175 9.3227 28.7582C6.50205 17.999 6.7181 9.30777 6.7181 9.30777Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "white",
      d: "M25.1172 8.8L39.9373 12.23C39.7663 15.5 39.233 21.28 37.5126 27.82C35.6814 34.77 28.1154 38.7 25.1273 40.01C22.1492 38.7 14.553 34.73 12.7319 27.82C11.0114 21.28 10.4681 15.5 10.2971 12.23L25.1172 8.8ZM25.1172 5L6.50403 9.31C6.50403 9.31 6.28269 18 9.11994 28.76C11.9572 39.52 25.1071 44 25.1071 44C25.1071 44 38.2571 39.52 41.0944 28.76C43.9316 18 43.7103 9.31 43.7103 9.31L25.1172 5Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "white",
      d: "M28.2306 29.4833V23.5801H28.2189C29.423 22.7267 30.2062 21.3357 30.2062 19.7459C30.2062 17.1275 28.0787 15 25.4602 15C22.8418 15 20.7143 17.1275 20.7143 19.7459C20.7143 21.324 21.5092 22.7034 22.7015 23.5567V29.4833H28.2306Z"
    }, null, -1)
  ])))
}
export default { render: render }